@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.u-pt0 {
  padding-top: 0px !important; }

.u-pb0 {
  padding-bottom: 0px !important; }

.u-mt0 {
  margin-top: 0px !important; }

.u-mb0 {
  margin-bottom: 0px !important; }

.u-pt5 {
  padding-top: 5px !important; }

.u-pb5 {
  padding-bottom: 5px !important; }

.u-mt5 {
  margin-top: 5px !important; }

.u-mb5 {
  margin-bottom: 5px !important; }

.u-pt10 {
  padding-top: 10px !important; }

.u-pb10 {
  padding-bottom: 10px !important; }

.u-mt10 {
  margin-top: 10px !important; }

.u-mb10 {
  margin-bottom: 10px !important; }

.u-pt15 {
  padding-top: 15px !important; }

.u-pb15 {
  padding-bottom: 15px !important; }

.u-mt15 {
  margin-top: 15px !important; }

.u-mb15 {
  margin-bottom: 15px !important; }

.u-pt20 {
  padding-top: 20px !important; }

.u-pb20 {
  padding-bottom: 20px !important; }

.u-mt20 {
  margin-top: 20px !important; }

.u-mb20 {
  margin-bottom: 20px !important; }

.u-pt25 {
  padding-top: 25px !important; }

.u-pb25 {
  padding-bottom: 25px !important; }

.u-mt25 {
  margin-top: 25px !important; }

.u-mb25 {
  margin-bottom: 25px !important; }

.u-pt30 {
  padding-top: 30px !important; }

.u-pb30 {
  padding-bottom: 30px !important; }

.u-mt30 {
  margin-top: 30px !important; }

.u-mb30 {
  margin-bottom: 30px !important; }

.u-pt35 {
  padding-top: 35px !important; }

.u-pb35 {
  padding-bottom: 35px !important; }

.u-mt35 {
  margin-top: 35px !important; }

.u-mb35 {
  margin-bottom: 35px !important; }

.u-pt40 {
  padding-top: 40px !important; }

.u-pb40 {
  padding-bottom: 40px !important; }

.u-mt40 {
  margin-top: 40px !important; }

.u-mb40 {
  margin-bottom: 40px !important; }

.u-pt45 {
  padding-top: 45px !important; }

.u-pb45 {
  padding-bottom: 45px !important; }

.u-mt45 {
  margin-top: 45px !important; }

.u-mb45 {
  margin-bottom: 45px !important; }

.u-pt50 {
  padding-top: 50px !important; }

.u-pb50 {
  padding-bottom: 50px !important; }

.u-mt50 {
  margin-top: 50px !important; }

.u-mb50 {
  margin-bottom: 50px !important; }

.u-pt55 {
  padding-top: 55px !important; }

.u-pb55 {
  padding-bottom: 55px !important; }

.u-mt55 {
  margin-top: 55px !important; }

.u-mb55 {
  margin-bottom: 55px !important; }

.u-pt60 {
  padding-top: 60px !important; }

.u-pb60 {
  padding-bottom: 60px !important; }

.u-mt60 {
  margin-top: 60px !important; }

.u-mb60 {
  margin-bottom: 60px !important; }

.u-pt65 {
  padding-top: 65px !important; }

.u-pb65 {
  padding-bottom: 65px !important; }

.u-mt65 {
  margin-top: 65px !important; }

.u-mb65 {
  margin-bottom: 65px !important; }

.u-pt70 {
  padding-top: 70px !important; }

.u-pb70 {
  padding-bottom: 70px !important; }

.u-mt70 {
  margin-top: 70px !important; }

.u-mb70 {
  margin-bottom: 70px !important; }

.u-pt75 {
  padding-top: 75px !important; }

.u-pb75 {
  padding-bottom: 75px !important; }

.u-mt75 {
  margin-top: 75px !important; }

.u-mb75 {
  margin-bottom: 75px !important; }

.u-pt80 {
  padding-top: 80px !important; }

.u-pb80 {
  padding-bottom: 80px !important; }

.u-mt80 {
  margin-top: 80px !important; }

.u-mb80 {
  margin-bottom: 80px !important; }

.u-pt85 {
  padding-top: 85px !important; }

.u-pb85 {
  padding-bottom: 85px !important; }

.u-mt85 {
  margin-top: 85px !important; }

.u-mb85 {
  margin-bottom: 85px !important; }

.u-pt90 {
  padding-top: 90px !important; }

.u-pb90 {
  padding-bottom: 90px !important; }

.u-mt90 {
  margin-top: 90px !important; }

.u-mb90 {
  margin-bottom: 90px !important; }

.u-pt95 {
  padding-top: 95px !important; }

.u-pb95 {
  padding-bottom: 95px !important; }

.u-mt95 {
  margin-top: 95px !important; }

.u-mb95 {
  margin-bottom: 95px !important; }

.u-pt100 {
  padding-top: 100px !important; }

.u-pb100 {
  padding-bottom: 100px !important; }

.u-mt100 {
  margin-top: 100px !important; }

.u-mb100 {
  margin-bottom: 100px !important; }

@media screen and (max-width: 1024px) {
  .u-md-pt0 {
    padding-top: 0px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb0 {
    padding-bottom: 0px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt0 {
    margin-top: 0px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb0 {
    margin-bottom: 0px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt5 {
    padding-top: 5px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb5 {
    padding-bottom: 5px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt5 {
    margin-top: 5px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb5 {
    margin-bottom: 5px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt10 {
    padding-top: 10px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb10 {
    padding-bottom: 10px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt10 {
    margin-top: 10px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb10 {
    margin-bottom: 10px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt15 {
    padding-top: 15px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb15 {
    padding-bottom: 15px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt15 {
    margin-top: 15px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb15 {
    margin-bottom: 15px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt20 {
    padding-top: 20px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb20 {
    padding-bottom: 20px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt20 {
    margin-top: 20px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb20 {
    margin-bottom: 20px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt25 {
    padding-top: 25px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb25 {
    padding-bottom: 25px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt25 {
    margin-top: 25px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb25 {
    margin-bottom: 25px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt30 {
    padding-top: 30px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb30 {
    padding-bottom: 30px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt30 {
    margin-top: 30px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb30 {
    margin-bottom: 30px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt35 {
    padding-top: 35px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb35 {
    padding-bottom: 35px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt35 {
    margin-top: 35px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb35 {
    margin-bottom: 35px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt40 {
    padding-top: 40px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb40 {
    padding-bottom: 40px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt40 {
    margin-top: 40px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb40 {
    margin-bottom: 40px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt45 {
    padding-top: 45px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb45 {
    padding-bottom: 45px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt45 {
    margin-top: 45px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb45 {
    margin-bottom: 45px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt50 {
    padding-top: 50px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb50 {
    padding-bottom: 50px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt50 {
    margin-top: 50px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb50 {
    margin-bottom: 50px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt55 {
    padding-top: 55px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb55 {
    padding-bottom: 55px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt55 {
    margin-top: 55px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb55 {
    margin-bottom: 55px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt60 {
    padding-top: 60px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb60 {
    padding-bottom: 60px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt60 {
    margin-top: 60px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb60 {
    margin-bottom: 60px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt65 {
    padding-top: 65px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb65 {
    padding-bottom: 65px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt65 {
    margin-top: 65px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb65 {
    margin-bottom: 65px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt70 {
    padding-top: 70px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb70 {
    padding-bottom: 70px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt70 {
    margin-top: 70px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb70 {
    margin-bottom: 70px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt75 {
    padding-top: 75px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb75 {
    padding-bottom: 75px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt75 {
    margin-top: 75px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb75 {
    margin-bottom: 75px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt80 {
    padding-top: 80px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb80 {
    padding-bottom: 80px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt80 {
    margin-top: 80px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb80 {
    margin-bottom: 80px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt85 {
    padding-top: 85px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb85 {
    padding-bottom: 85px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt85 {
    margin-top: 85px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb85 {
    margin-bottom: 85px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt90 {
    padding-top: 90px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb90 {
    padding-bottom: 90px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt90 {
    margin-top: 90px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb90 {
    margin-bottom: 90px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt95 {
    padding-top: 95px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb95 {
    padding-bottom: 95px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt95 {
    margin-top: 95px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb95 {
    margin-bottom: 95px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pt100 {
    padding-top: 100px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-pb100 {
    padding-bottom: 100px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mt100 {
    margin-top: 100px !important; } }

@media screen and (max-width: 1024px) {
  .u-md-mb100 {
    margin-bottom: 100px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt0 {
    padding-top: 0px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb0 {
    padding-bottom: 0px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt0 {
    margin-top: 0px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb0 {
    margin-bottom: 0px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt5 {
    padding-top: 5px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb5 {
    padding-bottom: 5px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt5 {
    margin-top: 5px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb5 {
    margin-bottom: 5px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt10 {
    padding-top: 10px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb10 {
    padding-bottom: 10px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt10 {
    margin-top: 10px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb10 {
    margin-bottom: 10px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt15 {
    padding-top: 15px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb15 {
    padding-bottom: 15px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt15 {
    margin-top: 15px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb15 {
    margin-bottom: 15px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt20 {
    padding-top: 20px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb20 {
    padding-bottom: 20px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt20 {
    margin-top: 20px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb20 {
    margin-bottom: 20px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt25 {
    padding-top: 25px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb25 {
    padding-bottom: 25px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt25 {
    margin-top: 25px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb25 {
    margin-bottom: 25px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt30 {
    padding-top: 30px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb30 {
    padding-bottom: 30px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt30 {
    margin-top: 30px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb30 {
    margin-bottom: 30px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt35 {
    padding-top: 35px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb35 {
    padding-bottom: 35px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt35 {
    margin-top: 35px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb35 {
    margin-bottom: 35px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt40 {
    padding-top: 40px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb40 {
    padding-bottom: 40px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt40 {
    margin-top: 40px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb40 {
    margin-bottom: 40px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt45 {
    padding-top: 45px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb45 {
    padding-bottom: 45px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt45 {
    margin-top: 45px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb45 {
    margin-bottom: 45px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt50 {
    padding-top: 50px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb50 {
    padding-bottom: 50px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt50 {
    margin-top: 50px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb50 {
    margin-bottom: 50px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt55 {
    padding-top: 55px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb55 {
    padding-bottom: 55px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt55 {
    margin-top: 55px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb55 {
    margin-bottom: 55px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt60 {
    padding-top: 60px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb60 {
    padding-bottom: 60px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt60 {
    margin-top: 60px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb60 {
    margin-bottom: 60px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt65 {
    padding-top: 65px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb65 {
    padding-bottom: 65px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt65 {
    margin-top: 65px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb65 {
    margin-bottom: 65px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt70 {
    padding-top: 70px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb70 {
    padding-bottom: 70px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt70 {
    margin-top: 70px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb70 {
    margin-bottom: 70px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt75 {
    padding-top: 75px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb75 {
    padding-bottom: 75px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt75 {
    margin-top: 75px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb75 {
    margin-bottom: 75px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt80 {
    padding-top: 80px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb80 {
    padding-bottom: 80px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt80 {
    margin-top: 80px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb80 {
    margin-bottom: 80px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt85 {
    padding-top: 85px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb85 {
    padding-bottom: 85px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt85 {
    margin-top: 85px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb85 {
    margin-bottom: 85px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt90 {
    padding-top: 90px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb90 {
    padding-bottom: 90px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt90 {
    margin-top: 90px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb90 {
    margin-bottom: 90px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt95 {
    padding-top: 95px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb95 {
    padding-bottom: 95px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt95 {
    margin-top: 95px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb95 {
    margin-bottom: 95px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pt100 {
    padding-top: 100px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-pb100 {
    padding-bottom: 100px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mt100 {
    margin-top: 100px !important; } }

@media screen and (max-width: 768px) {
  .u-sm-mb100 {
    margin-bottom: 100px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt0 {
    padding-top: 0px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb0 {
    padding-bottom: 0px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt0 {
    margin-top: 0px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb0 {
    margin-bottom: 0px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt5 {
    padding-top: 5px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb5 {
    padding-bottom: 5px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt5 {
    margin-top: 5px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb5 {
    margin-bottom: 5px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt10 {
    padding-top: 10px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb10 {
    padding-bottom: 10px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt10 {
    margin-top: 10px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb10 {
    margin-bottom: 10px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt15 {
    padding-top: 15px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb15 {
    padding-bottom: 15px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt15 {
    margin-top: 15px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb15 {
    margin-bottom: 15px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt20 {
    padding-top: 20px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb20 {
    padding-bottom: 20px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt20 {
    margin-top: 20px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb20 {
    margin-bottom: 20px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt25 {
    padding-top: 25px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb25 {
    padding-bottom: 25px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt25 {
    margin-top: 25px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb25 {
    margin-bottom: 25px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt30 {
    padding-top: 30px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb30 {
    padding-bottom: 30px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt30 {
    margin-top: 30px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb30 {
    margin-bottom: 30px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt35 {
    padding-top: 35px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb35 {
    padding-bottom: 35px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt35 {
    margin-top: 35px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb35 {
    margin-bottom: 35px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt40 {
    padding-top: 40px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb40 {
    padding-bottom: 40px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt40 {
    margin-top: 40px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb40 {
    margin-bottom: 40px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt45 {
    padding-top: 45px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb45 {
    padding-bottom: 45px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt45 {
    margin-top: 45px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb45 {
    margin-bottom: 45px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt50 {
    padding-top: 50px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb50 {
    padding-bottom: 50px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt50 {
    margin-top: 50px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb50 {
    margin-bottom: 50px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt55 {
    padding-top: 55px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb55 {
    padding-bottom: 55px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt55 {
    margin-top: 55px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb55 {
    margin-bottom: 55px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt60 {
    padding-top: 60px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb60 {
    padding-bottom: 60px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt60 {
    margin-top: 60px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb60 {
    margin-bottom: 60px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt65 {
    padding-top: 65px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb65 {
    padding-bottom: 65px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt65 {
    margin-top: 65px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb65 {
    margin-bottom: 65px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt70 {
    padding-top: 70px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb70 {
    padding-bottom: 70px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt70 {
    margin-top: 70px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb70 {
    margin-bottom: 70px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt75 {
    padding-top: 75px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb75 {
    padding-bottom: 75px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt75 {
    margin-top: 75px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb75 {
    margin-bottom: 75px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt80 {
    padding-top: 80px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb80 {
    padding-bottom: 80px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt80 {
    margin-top: 80px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb80 {
    margin-bottom: 80px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt85 {
    padding-top: 85px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb85 {
    padding-bottom: 85px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt85 {
    margin-top: 85px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb85 {
    margin-bottom: 85px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt90 {
    padding-top: 90px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb90 {
    padding-bottom: 90px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt90 {
    margin-top: 90px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb90 {
    margin-bottom: 90px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt95 {
    padding-top: 95px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb95 {
    padding-bottom: 95px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt95 {
    margin-top: 95px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb95 {
    margin-bottom: 95px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pt100 {
    padding-top: 100px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-pb100 {
    padding-bottom: 100px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mt100 {
    margin-top: 100px !important; } }

@media screen and (max-width: 568px) {
  .u-xs-mb100 {
    margin-bottom: 100px !important; } }

.u-bg--white {
  background-color: #FFFFFF; }

@keyframes slowDown {
  0% {
    top: -90px; }
  100% {
    top: 0; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    transform: translateX(30px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

#top {
  margin-top: 0; }
  #top .bgAnime-lines {
    clip-path: polygon(0 calc(100vh + 90px), 100% calc(100vh + 90px), 101% 100%, 0% 100%); }
    @media screen and (max-width: 1024px) {
      #top .bgAnime-lines {
        clip-path: polygon(0 calc(100vh + 75px), 100% calc(100vh + 75px), 101% 100%, 0% 100%); } }
  #top .l-header.is-animate {
    top: -90px;
    animation: slowDown 1s ease 1s 1 normal forwards; }
  @media screen and (max-width: 1024px) {
    #top .l-header__inner.is-animate {
      top: -75px;
      animation: slowDown 1s ease 1s 1 normal forwards; } }
  #top .l-f {
    z-index: 2; }
  .is-IE #top .bgAnime-lines {
    opacity: 0;
    transition: opacity 0.2s ease; }
  #top .p-mv {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 1;
    overflow: hidden; }
    @media screen and (max-width: 1024px) {
      #top .p-mv {
        height: 100vh; } }
    #top .p-mv::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: .5;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
    #top .p-mv__video {
      width: 100%;
      height: 100%;
      display: block;
      line-height: 1;
      object-fit: cover;
      object-position: center;
      font-family: "object-fit: cover; object-position: center;"; }
    #top .p-mv__copy {
      position: absolute;
      top: calc(50% + 90px / 2);
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 100%; }
      @media screen and (max-width: 1230px) {
        #top .p-mv__copy {
          top: calc(50% + 75px / 2); } }
      #top .p-mv__copy-img {
        max-width: 387px;
        width: 100%;
        margin: 0 auto 10px; }
        @media screen and (max-width: 768px) {
          #top .p-mv__copy-img {
            max-width: 360px;
            margin-bottom: 0; } }
        @media screen and (max-width: 568px) {
          #top .p-mv__copy-img {
            max-width: 280px; } }
        #top .p-mv__copy-img img {
          width: 100%;
          display: block;
          line-height: 1;
          opacity: 0; }
        #top .p-mv__copy-img-top {
          max-width: 210px;
          margin-bottom: -10px; }
          @media screen and (max-width: 568px) {
            #top .p-mv__copy-img-top {
              max-width: 155px;
              margin-bottom: -5px; } }
          #top .p-mv__copy-img-top.is-animate {
            transform: translateX(-30px);
            animation: fadeInFromLeft 1s ease 2s 1 normal forwards; }
        #top .p-mv__copy-img-middle {
          margin-bottom: -10px; }
          @media screen and (max-width: 568px) {
            #top .p-mv__copy-img-middle {
              margin-bottom: -5px; } }
          #top .p-mv__copy-img-middle.is-animate {
            transform: translateX(30px);
            animation: fadeInFromRight 1s ease 2s 1 normal forwards; }
        #top .p-mv__copy-img-bottom.is-animate {
          transform: translateX(-30px);
          animation: fadeInFromLeft 1s ease 2s 1 normal forwards; }
      #top .p-mv__copy-desc {
        font-size: 25px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 1.2;
        text-align: center;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
        margin: 0; }
        @media screen and (max-width: 768px) {
          #top .p-mv__copy-desc {
            font-size: 22px; } }
        @media screen and (max-width: 568px) {
          #top .p-mv__copy-desc {
            font-size: 18px; } }
        #top .p-mv__copy-desc.is-animate {
          opacity: 0;
          animation: fadeIn 1s ease 2s 1 normal forwards; }
  #top #wrapper {
    margin-top: 100vh;
    padding: 120px 0;
    position: relative;
    z-index: 2;
    background-color: #FFFFFF; }
    @media screen and (max-width: 1024px) {
      #top #wrapper {
        margin-top: 100vh;
        padding: 70px 0 60px; } }
    .eng #top #wrapper {
      padding-bottom: 0; }
      @media screen and (max-width: 1024px) {
        .eng #top #wrapper {
          padding-bottom: 0; } }
  #top .c-cvArea {
    background-color: white;
    position: relative;
    z-index: 1; }
  @media screen and (max-width: 1230px) {
    #top .c-ttl-line {
      padding-left: 15px;
      padding-right: 15px; } }
  #top .p-about {
    overflow: hidden;
    margin-bottom: 93.5px; }
    @media screen and (max-width: 1230px) {
      #top .p-about__content .c-ttl-lg {
        padding-left: 30px; } }
    @media screen and (max-width: 834px) {
      #top .p-about__content .c-ttl-lg {
        padding-left: 30px;
        padding-right: 30px; } }
    @media screen and (max-width: 768px) {
      #top .p-about__content .c-ttl-lg {
        padding-left: 15px;
        padding-right: 15px; } }
    .eng #top .p-about__content .c-ttl-lg {
      margin-bottom: 144px; }
    @media screen and (max-width: 834px) {
      #top .p-about__content .l-overlay__desc {
        background: linear-gradient(transparent 60%, #f4f4f4 60%);
        padding-bottom: 36px; } }
    @media screen and (max-width: 834px) {
      #top .p-about__content .l-overlay__img {
        display: none; } }
    @media screen and (max-width: 834px) {
      #top .p-about__content .l-bgWord:before {
        content: none; } }
    #top .p-about__btn {
      text-align: left; }
      @media screen and (max-width: 834px) {
        #top .p-about__btn {
          text-align: center; } }
    #top .p-about__spImg {
      display: none;
      position: relative; }
      @media screen and (max-width: 834px) {
        #top .p-about__spImg {
          display: block;
          margin: 15px -30px 35px; } }
      @media screen and (max-width: 768px) {
        #top .p-about__spImg {
          margin-right: -15px;
          margin-left: -15px; } }
      @media screen and (max-width: 834px) {
        #top .p-about__spImg:before {
          content: "About";
          font-family: "Arial";
          font-size: 190px;
          font-weight: 400;
          color: #f4f4f4;
          letter-spacing: -0.06em;
          line-height: 1;
          pointer-events: none;
          display: inline-block;
          position: absolute;
          top: -135px;
          left: -25px;
          z-index: -1; } }
  @media screen and (max-width: 834px) and (max-width: 568px) {
    #top .p-about__spImg:before {
      font-size: 100px; } }
      @media screen and (max-width: 568px) {
        #top .p-about__spImg:before {
          top: -75px;
          left: -10px; } }
    #top .p-about .p-enTxt {
      margin-bottom: 0; }
      @media screen and (max-width: 834px) {
        #top .p-about .p-enTxt {
          margin-bottom: 50px; } }
  #top .p-benefits {
    margin-bottom: 90px; }
    @media screen and (max-width: 834px) {
      #top .p-benefits {
        margin-bottom: 70px; } }
    @media screen and (max-width: 1230px) {
      #top .p-benefits__heading {
        padding-left: 30px;
        padding-right: 30px; } }
    @media screen and (max-width: 768px) {
      #top .p-benefits__heading {
        padding-left: 15px;
        padding-right: 15px; } }
    @media screen and (max-width: 568px) {
      #top .p-benefits .l-bgWord::before {
        top: -78px;
        left: -7px; } }
    #top .p-benefits .c-checkCard {
      max-width: 789px;
      margin-top: -210px;
      margin-left: auto; }
      @media screen and (max-width: 834px) {
        #top .p-benefits .c-checkCard {
          max-width: calc(100% - 40px);
          margin: -170px auto 0; } }
      @media screen and (max-width: 768px) {
        #top .p-benefits .c-checkCard {
          margin: -28px auto 0; } }
      @media screen and (max-width: 768px) {
        #top .p-benefits .c-checkCard {
          max-width: calc(100% - 30px); } }
      #top .p-benefits .c-checkCard__item {
        width: calc(100% / 3); }
        @media screen and (max-width: 834px) {
          #top .p-benefits .c-checkCard__item {
            width: 100%; } }
  #top .p-service {
    padding-top: 50px;
    margin-bottom: 120px;
    position: relative; }
    #top .p-service:before {
      content: '';
      display: block;
      width: 100%;
      height: 270px;
      background: url("/inc/image/common/bg/bg_dots.png");
      position: absolute;
      top: 0;
      left: 0; }
    #top .p-service:after {
      content: '';
      display: block;
      width: 100%;
      height: calc(100% - 270px);
      background-color: #FFFFFF;
      position: absolute;
      bottom: 0;
      left: 0; }
    #top .p-service__more {
      text-align: center;
      padding-bottom: 104px; }
    @media screen and (max-width: 1024px) {
      #top .p-service .c-tile__img {
        width: 150px;
        height: 150px; } }
    @media screen and (max-width: 568px) {
      #top .p-service .c-tile__img {
        width: 130px;
        height: 130px; } }
  #top .p-classifying {
    position: relative; }
    #top .p-classifying:after {
      content: '';
      display: block;
      width: 100%;
      height: calc(100% - 280px);
      background-color: #f4f4f4;
      position: absolute;
      bottom: 0;
      left: 0; }
    #top .p-classifying__inner {
      position: relative; }
    @media screen and (max-width: 768px) {
      #top .p-classifying__container {
        padding-right: 0;
        padding-left: 0; } }
    #top .p-classifying__wrapper {
      max-width: 1100px;
      margin: 0 auto; }
      @media screen and (max-width: 1024px) {
        #top .p-classifying__wrapper {
          max-width: auto;
          margin: 0; } }
      #top .p-classifying__wrapper .slick-track {
        margin: 0; }
        @media screen and (max-width: 1100px) {
          #top .p-classifying__wrapper .slick-track {
            margin-right: auto;
            margin-left: auto; } }
      #top .p-classifying__wrapper .c-materialCard__wrap {
        left: -115px; }
        @media screen and (max-width: 1230px) {
          #top .p-classifying__wrapper .c-materialCard__wrap {
            left: -90px; } }
        @media screen and (max-width: 1100px) {
          #top .p-classifying__wrapper .c-materialCard__wrap {
            left: 0; } }
    @media screen and (max-width: 568px) {
      #top .p-classifying .l-bgWord::before {
        top: -10px;
        left: -10px; } }
    #top .p-classifying .c-slider__control {
      position: absolute;
      top: -50px;
      right: 15px; }
    #top .p-classifying__more {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
      @media screen and (max-width: 1100px) {
        #top .p-classifying__more {
          position: relative;
          top: 0;
          right: auto;
          transform: none;
          text-align: center; } }
  #top .p-field {
    padding-top: 80px;
    position: relative;
    margin-bottom: 120px; }
    @media screen and (max-width: 768px) {
      #top .p-field {
        margin-bottom: 70px; } }
    #top .p-field:before {
      content: '';
      display: block;
      width: 100%;
      height: 280px;
      background-color: #f4f4f4;
      position: absolute;
      top: 0;
      left: 0; }
    @media screen and (max-width: 768px) {
      #top .p-field__wrap {
        max-width: 400px;
        width: 100%;
        margin: 0 auto; } }
    #top .p-field__list {
      display: flex;
      position: relative;
      left: -16px; }
      @media screen and (max-width: 1100px) {
        #top .p-field__list {
          left: 0;
          justify-content: center;
          margin-bottom: 77px; } }
      @media screen and (max-width: 768px) {
        #top .p-field__list {
          flex-wrap: wrap;
          margin: 0 -30px 77px; } }
      @media screen and (max-width: 568px) {
        #top .p-field__list {
          margin-right: 0;
          margin-left: 0; } }
      #top .p-field__list-item {
        max-width: 170px;
        width: 100%;
        margin-right: 73px; }
        @media screen and (max-width: 1024px) {
          #top .p-field__list-item {
            margin-right: 50px; } }
        @media screen and (max-width: 768px) {
          #top .p-field__list-item {
            margin-right: 0;
            max-width: 50%;
            width: 100%;
            padding: 0 30px 37px; } }
        @media screen and (max-width: 568px) {
          #top .p-field__list-item {
            padding-left: 15px;
            padding-right: 15px; } }
        #top .p-field__list-item:last-child {
          margin-right: 0; }
      #top .p-field__list-img {
        margin-bottom: 30px; }
      #top .p-field__list-name {
        font-size: 16px;
        text-align: center;
        margin-bottom: 0; }
    @media screen and (max-width: 568px) {
      #top .p-field .l-bgWord::before {
        top: -80px;
        left: -10px; } }
    #top .p-field__more {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
      @media screen and (max-width: 1100px) {
        #top .p-field__more {
          position: relative;
          top: 0;
          right: auto;
          transform: none;
          text-align: center; } }
      @media screen and (max-width: 568px) {
        #top .p-field__more {
          top: -68px; } }
  #top .p-cases {
    position: relative;
    padding-top: 53px;
    margin-bottom: 100px; }
    @media screen and (max-width: 768px) {
      #top .p-cases {
        margin-bottom: 70px; } }
    #top .p-cases:before {
      content: '';
      display: inline-block;
      max-width: 100%;
      width: calc(((100% - 1200px) / 2) + 1200px);
      height: calc(100% - 50px);
      background-color: #f4f4f4;
      position: absolute;
      top: 0;
      left: 0; }
      @media screen and (max-width: 768px) {
        #top .p-cases:before {
          width: 100%;
          height: calc(100% - 100px); } }
    #top .p-cases__more {
      text-align: center;
      padding-bottom: 104px; }
    @media screen and (max-width: 568px) {
      #top .p-cases .l-bgWord::before {
        top: -80px; } }
  #top .p-flow {
    position: relative;
    padding-top: 57px;
    margin-bottom: 100px; }
    @media screen and (max-width: 768px) {
      #top .p-flow {
        margin-bottom: 70px; } }
    #top .p-flow:before {
      content: '';
      display: block;
      width: 100%;
      height: 250px;
      background: url("/inc/image/common/bg/bg_dots.png");
      position: absolute;
      top: 0;
      left: 0; }
    #top .p-flow__inner {
      position: relative; }
    #top .p-flow__more {
      text-align: center;
      margin-top: 40px; }
    @media screen and (max-width: 568px) {
      #top .p-flow .l-bgWord::before {
        top: -90px;
        left: -10px; } }
  #top .p-qa {
    padding-top: 60px;
    padding-bottom: 63px;
    background-color: #f4f4f4; }
    #top .p-qa__inner {
      max-width: 960px;
      margin: 0 auto; }
    #top .p-qa__more {
      text-align: center;
      margin-top: 40px; }
    #top .p-qa .c-ttl-line {
      margin-bottom: 68px; }
    @media screen and (max-width: 568px) {
      #top .p-qa .l-bgWord::before {
        top: -75px;
        left: -10px; } }
  #top .p-news {
    padding-top: 60px;
    padding-bottom: 40px; }
    #top .p-news__more {
      text-align: center;
      margin-top: 40px; }
    #top .p-news .c-ttl-line {
      margin-bottom: 50px; }
  @media screen and (max-width: 568px) {
    #top .l-bgWord::before {
      top: -75px;
      left: -10px; } }
